<template>
  <div class="po-re">
    <div class="cont_zar_view" :class="{ loading }" v-if="items">
      <div class="row">
        <div class="col1">
          <div>
            <zar-bread v-if="cats && !drawerMode" :srcCat="cats" :srcObj="getObjInfo(obj)" v-on:clickedBread="clickedBread($event)" class="mb-2" :class="{ 'ml-10px': scr == 1 }" />
            <div v-if="scr == 1 && !drawerMode" class="mt-3"></div>
            <span v-if="!drawerMode" class="content_back ml-3"><ui-button text="Буцах" icon="i-next" angle="rev" v-on:clicked="goBack()" tp="simple" widthSize="wide"/></span>
            <zar-content class="mt-5" :drawerMode="drawerMode" :items="items" :data="{ dep, obj, zid, rdate: zarDate.rdate, date: zarDate.date, rdateRaw: zarDate.raw, oldPrice }" centerPropName :nearLoc="nearLoc" :spo="spo" />
          </div>
        </div>
        <div class="col2">
          <div v-if="itemExpired" class="expired">
            Энэ зар <b>{{ zarDate.rdate }}</b> орсон байгааг анхаарна уу
          </div>
          <template>
            <div v-if="itemDisabled" class="warning_text m-auto mb-5 mt-5">{{ itemDisabled == 10 ? "Зарагдсан" : "Зар идэвхигүй байна" }}</div>
            <div class="mb-5 "></div>
          </template>
          <div class="stick">
            <div :class="scr == 1 ? 'hori_max' : 'hori'" v-if="scr == 1"></div>
            <zar-content :items="itemsSide" :owner="owner" :bofferEnabled="bofferEnabled" :bofferNum="bofferNum" v-on:showAddBoffer="showAddBoffer = true" :data="{ dep, obj, zid, rdate: zarDate.rdate, date: zarDate.date, oldPrice }" />
            <template v-if="!itemDisabled">
              <side-panel :user="userItem" :obj="obj" :zid="zid" :viewCount="viewCount" v-on:reloadItem="prepData()" />
              <!-- <zar-report /> -->
              <div class="cont_social mt-3" :class="{ 'ml-10px': scr == 1 }">
                <ShareNetwork network="facebook" :url="currentUrl" :title="meta.title" hashtags="zaraa">
                  <ui-icon name="i-facebook" size="xxxl" />
                </ShareNetwork>
                <ShareNetwork network="twitter" :url="currentUrl" :title="meta.title" hashtags="zaraa">
                  <ui-icon name="i-x" size="xxxl" />
                </ShareNetwork>
                <ui-icon class="clickable" @click="showItemLink()" name="i-link" size="xxxl" />
                <input class="copy_input" v-on:focus="$event.target.select()" ref="clone" readonly :value="'https://zaraa.mn/zar/view?o=' + obj + '&z=' + zid" />
              </div>

              <span class="report link ml-2" :class="{ 'ml-10px': scr == 1, has_report: reports }" @click="$bus.emit('openModal', { name: 'zarReport', dataSet: { zid, obj } })">
                <ui-icon name="i-report" size="xl" class="po-re top-5 pr-1"></ui-icon>Мэдүүллэх
                <div v-if="reports" class="numberCircle">{{ reports }}</div>
              </span>
            </template>
          </div>
        </div>
        <!-- <div class="col2" v-if="itemDisabled">
          <div class="mt-5 pt-3"></div>
        </div> -->
      </div>

      <!-- <div  class="bottom_marker">Bottom of container</div> -->

      <div v-if="zarPool" class="cont_pool">
        <div class="mb-1"><ui-button text="Буцах" v-on:clicked="goBack()" tp="simple" /> {{ itemSource.name }}</div>
        <div class="wrap_pool">
          <zar-list :items="itemPool" v-on:clickedItem="clickedItem($event)" miniMode />
          <span @click="$bus.emit('nextPage', false)">Next</span>
        </div>
      </div>

      <ch-drawer :visible.sync="showItemPool" area="430px" :title="itemSource.name" :blur="false" location="right" :mask="false" destroy-on-close>
        <zar-tag v-if="itemSource.src == 'homeZarList'" :controllable="false" />
        <zar-list :items="itemPool" v-on:clickedItem="clickedItem($event)" miniMode :loading="gLoading" />
        <div v-if="scr == 1" class="return_button_right" @click="showItemPool = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        <ui-paging v-if="itemSource.src == 'homeZarList' && totalPageHome >= pageNumHome" pagedView :curPage="pageNumHome" :totalPage="totalPageHome" v-on:clickedPageNum="$bus.emit('changePageHome', $event)" v-on:next="next()" />
        <div class="return_button_top" @click="showItemPool = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      </ch-drawer>

      <!-- <span class="link" @click="showNextItem">show next item</span> -->
    </div>
    <div v-else>
      <div class="cont_not_found" v-if="showNotFound">
        <img class="cat_playing" src="/icon/i-cat.svg" />
        <div class="mb-5">ЗАР ОЛДСОНГҮЙ...</div>
        <zar-list-home-front />
      </div>
      <div v-else>
        <div v-if="showPlaceholder" class="row">
          <div class="col1">
            <div class="post">
              <h4 class="title placeholder mb-5"></h4>
              <h4 class="title placeholder"></h4>
              <h4 class="title placeholder"></h4>
              <div class="post-image placeholder mb-1"></div>
              <div class="cont_image">
                <div class="post-image placeholder"></div>
                <div class="post-image placeholder"></div>
                <div class="post-image placeholder"></div>
                <div class="post-image placeholder"></div>
                <div class="post-image placeholder"></div>
              </div>
              <br />

              <p class="text placeholder"></p>
              <p class="text placeholder"></p>
              <p class="text placeholder"></p>
              <p class="text placeholder"></p>
              <p class="text placeholder"></p>
              <p class="text placeholder"></p>
            </div>
          </div>
          <div class="col2">
            <br />
            <h4 class="title placeholder"></h4>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
            <br />
            <h4 class="title placeholder"></h4>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
            <p class="text placeholder"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cont_back left" @click="goBack()" :style="{ width: calcWidth + 'px' }"></div>
    <div class="left_back" :style="{ display: calcWidth < 50 ? 'none' : 'block' }" @click="goBack()"><ui-icon name="i-arrow-left" size="xxxl" /></div>

    <div v-if="itemPool.length && prevItem && scr != 1" class="cont_navi prev">
      <div @click="goPrev()" class="navi_image">
        <ui-icon name="i-next" angle="rev" size="l" />
      </div>
    </div>
    <div v-if="itemPool.length && scr != 1" class="cont_navi next">
      <div v-if="nextItem" @click="goNext()" class="navi_image">
        <ui-icon name="i-next" size="xxxl" />
        <span v-if="itemPool.length - curItemPos - 1 > 0" class="count">{{ itemPool.length - curItemPos - 1 }}</span>
      </div>
    </div>

    <div v-if="!drawerMode" class="cont_back right"><ui-icon class="back_icon" name="i-back" @click="goBack()" size="xxxxl" /></div>
    <div v-if="!drawerMode && itemPool.length && scr != 1" class="trigger" @click="showItemPool = true">
      <div>{{ itemSource.name }}</div>
      <ui-icon v-if="menuIcon" :name="menuIcon" size="xxxxl" />
    </div>

    <div class="navi_simple" v-if="drawerMode && scr == 1 && itemPool.length">
      <div class="left" @click="goPrev()"><ui-icon v-if="prevItem" name="i-next" size="xl" angle="rev" /></div>
      <div class="right" @click="goNext()"><ui-icon v-if="nextItem" name="i-next" size="xl" /></div>
    </div>
    <!-- 
    <div v-if="obj && obj == 1 && items && items.length && itemSource.src != 'related_items' && zid && !itemPool.length && prevRouteName != 'direct'" class="related_items" v-observe-visibility="reachedRelatedItems"> -->

    <div v-if="itemPool.length && items && itemSource.src != 'related_items'" class="bottom_item_pool" :class="[itemSource.src == 'related_items' && 'related_items', itemSource.src == 'most_viewed' && 'most_viewed']" v-observe-visibility="reachedBottomItemPool">
      <div class="title_block" v-if="showBottomItemPool">{{ itemSource.name }}</div>
      <zar-tag v-if="showBottomItemPool && itemSource.src == 'homeZarList'" :controllable="false" />
      <zar-list v-if="showBottomItemPool" :items="itemPool" v-on:clickedItem="clickedItem($event)" nanoMode viewMain :loading="gLoading" :cusFieldAllowed="['title', 'price']" />
      <ui-paging v-if="showBottomItemPool && itemSource.src == 'homeZarList' && totalPageHome >= pageNumHome" pagedView :curPage="pageNumHome" :totalPage="totalPageHome" v-on:clickedPageNum="$bus.emit('changePageHome', $event)" v-on:next="next()" />
    </div>

    <div v-if="obj && obj == 1 && items && items.length" class="related_items" v-observe-visibility="reachedRelatedItems">
      <div class="title_block" v-if="showRelatedItems">Төстэй зарууд</div>
      <zar-list-self v-if="showRelatedItems && obj && zid" :nameSrc="'related_items'" v-on:clickedItem="clickedRelatedItems($event)" viewMain nanoMode :cusFieldAllowed="['title', 'price']" :paged="true" :customData="{ obj, zid }" />
    </div>

    <div v-if="items && items.length && prevRouteName == 'direct' && !drawerMode" class="most_viewed" v-observe-visibility="reachedMostViewed">
      <div class="title_block" v-if="showMostViewed">Өчигдөрийн их үзсэн зарууд</div>
      <zar-list-self v-if="showMostViewed" :nameSrc="'most_viewed'" viewMain nanoMode :cusFieldAllowed="['title', 'price']" :paged="true" :customData="obj" />
    </div>

    <span v-hotkey="keymap"></span>

    <ch-drawer
      @open="$store.commit('addOpenDrawers', 'addBoffer')"
      @close="$store.commit('removeOpenDrawers', 'addBoffer')"
      :visible.sync="showAddBoffer"
      v-on:close="showAddBoffer = false"
      :area="scr == 1 ? '100%' : '550px'"
      title="Машин санал болгох"
      :blur="false"
      :location="scr == 1 ? 'bottom' : 'right'"
      :escape-on-click="false"
      destroy-on-close
    >
      <div class="cont_suggest_text ani_sdown">
        <div class="info_text">Та нэг зард нэг удаа л өөрийн солих машинаа санал болгож болно. Таны саналыг зарын эзэн үзсэн эсэхийг та өөрийн хэрэглэгчийн хэсгээc харах боломжтой. Зарын эзэн шаардлагатай бол таны утасны дугаараар холбогдох болно.</div>
      </div>
      <zar-add-boffer :dataSet="{ obj: this.obj, zid: this.zid }" v-on:close="showAddBoffer = false" />
      <div v-if="scr == 1" class="return_button_right" @click="showAddBoffer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="showAddBoffer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarContent from "./content.vue";
import sidePanel from "./sidePanel.vue";
import zarList from "@/comp/zar/list.vue";
import zarBread from "@/comp/zar/bread.vue";
import zarListHomeFront from "@/comp/page/home/zarListFront.vue";
import zarListSelf from "@/comp/zar/listSelf.vue";
import zarAddBoffer from "@/comp/mod/addBoffer.vue";
import zarTag from "@/comp/zar/tag.vue";
import uiPaging from "@/comp/ui/paging.vue";

export default {
  name: "zar-view",
  components: {
    zarContent,
    sidePanel,
    zarList,
    zarBread,
    zarListHomeFront,
    zarListSelf,
    zarAddBoffer,
    zarTag,
    uiPaging,
  },
  metaInfo() {
    return {
      title: (this.newZar.length ? "(" + this.newZar.length + ") " : "") + this.meta.title,
      meta: [
        { name: "description", content: "Зарын цогц систем" },
        { property: "og:title", content: this.meta.title },
        { property: "og:site_name", content: "zaraa.mn" },
        { property: "og:description", content: this.meta.body },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://zaraa.mn/zar/view?o=" + this.$route.query.o + "&z=" + this.$route.query.z },
        { property: "og:image", content: this.meta.image },
      ],
    };
  },
  props: {
    dataSet: Object,
    drawerMode: Boolean,
    hasChild: Boolean,
  },
  data() {
    return {
      items: null,
      itemsSide: null,
      dep: null,
      userItem: null,
      viewCount: null,
      // viewPhoneCount: null,
      expand: false,
      zarDate: {},
      showItemPool: false,
      zarPool: false,
      prevRoute: null,
      reports: 0,
      itemDisabled: null,
      showNotFound: false,
      nearLoc: null,
      meta: { title: "", desc: "", image: "" },
      showPlaceholder: false,
      spo: 0,
      bofferEnabled: false,
      bofferNum: 0,
      oldPrice: 1,
      showAddBoffer: false,
      loading: false,
      showMostViewed: false,
      showRelatedItems: false,
      showBottomItemPool: false,
      itemExpired: false,
      itemExpireDay: null,
    };
  },
  methods: {
    reset() {
      if (this.scr == 1) this.items = null;
      this.showNotFound = false;
      this.showPlaceholder = false;
    },
    // clickedMostViewed(items) {
    //   this.$store.commit("zar/itemSource", { name: "Их үзэлттэй зар", src: "most_viewed", data: items });
    // },
    clickedRelatedItems(items) {
      this.$store.commit("zar/itemSource", { name: "Төстэй зарууд", src: "related_items", data: items });
    },
    // showNextItem() {
    //   this.$bus.emit("openDrawer", { name: "viewItem2", obj: this.nextItem.obj, zid: this.nextItem.zid });
    // },
    reachedMostViewed(visible) {
      if (visible) this.showMostViewed = true;
      // if (this.drawerMode && visible) {
      // this.$bus.emit("closeDrawer", "viewItem");
      // }
      // if(bool);
    },
    reachedRelatedItems(visible) {
      if (visible) this.showRelatedItems = true;
      // if (this.drawerMode && visible) {
      // this.$bus.emit("closeDrawer", "viewItem");
      // }
      // if(bool);
    },
    reachedBottomItemPool(visible) {
      if (visible) this.showBottomItemPool = true;
      // if (this.drawerMode && visible) {
      // this.$bus.emit("closeDrawer", "viewItem");
      // }
      // if(bool);
    },
    // showAddBoffer() {
    // this.$bus.emit("openModal", { name: "zarAddBoffer", dataSet: { obj: this.obj, zid: this.zid } });
    // },
    clickedBread(ob) {
      if (ob.class == "showHome") {
        // this.$router.push({ name: "home" });
        // if (this.homeUrl) history.replaceState(null, null, this.homeUrl);
        // this.$store.commit("selCat", ob.id);
        // this.$router.push({ name: "home", obj: 1 });
        // this.$router.go("");
      }
      if (ob.class == "obj") {
        let query = { o: ob.id };
        this.$bus.emit("forceSelObj", ob.id);
        this.$router.push({ name: "home", query });
        this.$nextTick(() => this.$store.commit("showMenu", { cat: true }));
      }
      if (ob.class == "cat") {
        this.$router.push({ name: "home" });
        this.$nextTick(() => this.$store.commit("showMenu", { cat: true }));
        setTimeout(() => this.$bus.emit("forceSelCat", ob.id), 200);
      }
    },
    showItemLink() {
      // navigator.clipboard.writeText(window.location.href);
      this.$refs.clone.focus();
      document.execCommand("copy");
      this.$modal.show("dialog", {
        text: "Зарын холбоос дэлгэцэнд хуулагдлаа",
        buttons: [
          {
            title: "ОК",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          // },
          // {
          //   title: "Дэлгэцэнд хуулах",
          //   handler: () => {
          //     alert("Repost action");
          //   },
          // },
        ],
      });
    },
    togglePool() {
      this.zarPool = !this.zarPool;
    },
    goPrev() {
      if (!this.itemPool.length) return;

      if (this.drawerMode && this.prevItem) {
        this.$bus.emit("openDrawer", { name: "viewItem", obj: this.prevItem.obj, zid: this.prevItem.zid });
        return;
      }

      this.$scrollTo("#header", 300);
      if (this.prevItem) this.clickedItem({ obj: this.prevItem.obj, zid: this.prevItem.zid });
      else this.showMessage("info", "Дууссан");
    },
    goNext() {
      if (!this.itemPool.length) return;

      if (this.drawerMode && this.nextItem) {
        this.$bus.emit("openDrawer", { name: "viewItem", obj: this.nextItem.obj, zid: this.nextItem.zid });
        return;
      }

      this.$scrollTo("#header", 300);
      if (this.nextItem) this.clickedItem({ obj: this.nextItem.obj, zid: this.nextItem.zid });
      else this.showMessage("info", "Дууссан");
    },
    async clickedItem({ obj, zid }) {
      // this.obj = obj;
      // this.zid = zid;
      // this.$store.commit("zar/witem", { obj, zid });
      // this.$bus.emit("scrollTo", "#header");
      // await this.prepData();
      // history.replaceState("", "", "view?o=" + obj + "&z=" + zid);

      this.$router.replace({ query: { o: obj, z: zid } });
      if (this.scr == 1) this.showItemPool = false;
      this.$scrollTo("#header", 0);
    },
    async fetchItemData() {
      // let found = this.cacheOneItem.find((e) => e.obj == this.obj && e.zid == this.zid && Date.now() - e.date < 1000 * 5);
      // if (found) return found.data;
      let data = await this.axiosGet("/zar/item/getOneItem", [this.obj, this.zid, this.prevRouteName]);
      // if (data) this.cacheOneItem.push({ obj: this.obj, zid: this.zid, data, date: Date.now() });
      return data;
    },

    async checkBofferEnabled() {
      let data = await this.axiosGet("/module/barter/checkBofferEnabled", [this.zid]);
      // this.bofferNum = data.boffer_num;
      if (data.status) this.bofferEnabled = true;
    },

    async prepData() {
      this.loading = true;
      // setTimeout(() => (this.loading = false), 2000);

      let data = await this.fetchItemData();
      this.loading = false;

      if (data == "not found") {
        this.showNotFound = true;
        this.showMessage("warning", "Зар олдсонгүй", 3000);
        return;
      }
      // if (!pinfoMain) return;

      this.addViewHistory();

      let item = data.item;

      // item = Object.freeze(item);
      // if (!item.dis) this.fetchUserInfo(item.uid);
      this.dep = item.dep;
      this.itemDisabled = item.dis;
      this.reports = item.report;
      this.spo = item.spo;
      this.bofferNum = item.boffer;
      this.zarDate.rdate = this.$date.unix(item.rdate).fromNow(true);
      this.zarDate.date = this.$date.unix(item.rdate).format("YYYY.MM.DD[-ний] HH:mm");
      this.zarDate.raw = item.rdate;
      this.oldPrice = item.old_price;
      this.viewCount = item.view;
      // this.viewPhoneCount = item.view_phone;
      this.items = data.pinfo.main;
      this.itemsSide = data.pinfo.side;
      this.userItem = data.user ?? null;

      this.dataSetup(data.pinfo.main, item);
      this.dataSetup(data.pinfo.side, item);

      let aid = null;
      let findSide = this.itemsSide.find((e) => e.ptype == "location_area");
      if (findSide) aid = findSide.id;
      else {
        let findMain = this.itemsSide.find((e) => e.ptype == "area");
        if (findMain) aid = findMain.id;
      }

      // this.nearLoc = aid && this.userInfo ? (item["p" + aid] == this.userInfo.loc_day ? 1 : item["p" + aid] == this.userInfo.loc_night ? 2 : false) : false;
      let tp = null;
      if (aid && this.userInfo) {
        let zarArea = item["p" + aid];
        let userDayArea = this.userInfo.loc_day;
        let userNightArea = this.userInfo.loc_night;

        if (zarArea == userDayArea) tp = 1;
        if (zarArea == userNightArea) tp = 2;
        if (!tp && this.nearestLoc) {
          let area = this.nearestLoc.find((e) => e.area == zarArea);
          if (area) {
            let nearest = area.nearest.split(",");
            let find1 = nearest.find((e) => e == userDayArea);
            let find2 = nearest.find((e) => e == userNightArea);
            if (find1) tp = 3;
            if (find2) tp = 4;
          }
        }
      }
      this.nearLoc = tp;

      this.bofferEnabled = false;
      // this.bofferNum = 0;
      if (this.obj == 1) this.checkBofferEnabled();
    },

    dataSetup(pinfo, data) {
      pinfo.forEach((e) => (e.val = "")); //CLEARING OLD CACHED ZAR DATAS
      pinfo.forEach((el) => {
        if (data["p" + el.id]) {
          if (el.pcomp_tp == 3) {
            let url = this.apiUrl + "/media/zar/" + data.obj + "/" + data.zid + "/" + el.id + "/";
            el.val = data["p" + el.id];
            let images = [];
            el.val.forEach((i) => {
              let pn = i.id + ".jpg";
              images.push({
                id: i.id,
                src: url + "b" + pn,
                tsrc: url + "t" + pn,
                msrc: url + "m" + pn,
                title: i.title,
                w: i.w,
                h: i.h,
                alt: this.meta ? this.meta.title : "",
              });
            });
            el.photoData = images;
            this.meta.image = images[0].msrc;
          } else if (el.pcomp_tp == 5) {
            el.val = data["p" + el.id];
          } else if (el.ptype == "title") {
            this.meta.title = data["p" + el.id];
            el.val = data["p" + el.id];
          } else if (el.ptype == "body") {
            this.meta.body = data["p" + el.id];
            el.val = data["p" + el.id];
            // } else if (el.ptype == "price" && data.dis) {
            // el.val = "";

            // } else if (el.pcomp_tp == 1) {
            //   el.val_id = data["p" + el.id];
            //   el.val = data["p" + el.id + "_val"];
          } else {
            el.val = data["p" + el.id];
            if (data["p" + el.id + "_val"]) el.valData = data["p" + el.id + "_val"];
            if (el.conver && el.val) el.val = this.converters(el.conver, el.val);
          }
        }
      });
      // console.log("pinfo:", pinfo);
    },

    // getMeta(url, id, images) {
    //   var img = new Image();
    //   img.src = url;
    //   img.onload = function() {
    //     let rw = this.width;
    //     let rh = this.height;
    //     if (rw > rh) {
    //       rh = rh * (1900 / rw);
    //       rw = 1900;
    //     } else {
    //       rw = rw * (1400 / rh);
    //       rh = 1400;
    //     }
    //     let el = images.find((i) => i.id == id);
    //     el.w = rw;
    //     el.h = rh;
    //   };
    // },
    goBack() {
      if (this.drawerMode) {
        this.$emit("close");
        return;
      }
      // if (this.$route.name == "zarAdd" || this.$route.name == "userHome") this.$router.push({ name: "home" });
      if (this.routeFrom == "zarView") this.$router.push({ name: "home" });
      else this.$router.go(-1); //showing error

      // if (this.homeUrl) history.replaceState(null, null, this.homeUrl);
    },
    addViewHistory() {
      this.$store.commit("user/addViewHistory", { obj: this.obj, zid: this.zid });
    },
    getParCat(data, cat) {
      let names = [];
      let found = data.find((e) => e.id == cat);
      if (!found) return [];

      names.push({ id: found.id, name: found.name });
      return found.par ? names.concat(this.getParCat(data, found.par)) : names;
    },
    getObjInfo(obj) {
      let data = this.catData;
      if (!data) return false;

      let parCat = data.obj.find((e) => e.id == obj);
      return parCat;
    },
    checkReachBottom() {
      //console.log("checking...");

      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
      this.reachedBottom = bottomOfWindow ? true : false;
      //console.log("reach:", this.reachedBottom);
    },
    showPoolList(dayOfMonth) {
      setTimeout(() => (this.showItemPool = true), 200);
      this.saveLocal("dayOfViewedPool", dayOfMonth);
    },
    checkItemExpired() {
      if (!this.catData || !this.zarDate.raw) return false;

      let object = this.catData.obj.find((e) => e.id == this.obj);
      if (!object || !object.configs) return false;

      let expireTime = JSON.parse(object.configs)["expire_time"];
      if (!expireTime) return false;

      this.itemExpired = this.zarDate.raw < Date.now() / 1000 - expireTime * 60 * 60 * 24;
      this.itemExpireDay = expireTime;
    },
  },
  beforeRouteEnter: (to, from, next) =>
    next((vm) => {
      vm.prevRoute = from;
    }),
  computed: {
    routeFrom() {
      return this.$store.state.routeFrom;
    },
    gLoading() {
      return this.$store.state.gLoading;
    },
    totalPageHome: {
      get() {
        return this.$store.state.homeZarListTotalPage;
      },
      set(num) {
        this.$store.commit("homeZarListTotalPage", num);
      },
    },
    pageNumHome: {
      get() {
        return this.$store.state.homeZarListPageNum;
      },
      set(num) {
        this.$store.commit("homeZarListPageNum", num);
      },
    },
    owner() {
      return this.userItem && this.userInfo && this.userItem.id == this.userInfo.id;
    },
    newZar() {
      return this.$store.state.newZar;
    },
    currentUrl() {
      return window.location.href;
    },
    cats() {
      if (!this.obj || !this.catData) return [];
      let data = this.catData;
      let parCat = data.objCat.find((e) => e.obj == this.obj);
      return this.getParCat(data.cat, parCat.cat).reverse();
    },
    cachePropType: {
      get() {
        return this.$store.state.cachePropType;
      },
      set(ob) {
        this.$store.commit("cachePropType", ob);
      },
    },
    cacheOneItem: {
      get() {
        return this.$store.state.cacheOneItem;
      },
      set(ob) {
        this.$store.commit("cacheOneItem", ob);
      },
    },
    prevRouteName() {
      return this.prevRoute && this.prevRoute.name ? this.prevRoute.name : "direct";
    },
    homeUrl() {
      return this.$store.state.homeUrl;
    },
    nearestLoc() {
      return this.$store.state.nearestLoc;
    },
    keymap() {
      return {
        // bind to hotkey
        left: this.goPrev,
        right: this.goNext,
        space: this.goNext,
        backspace: this.goBack,
        esc: this.goBack,
        l: this.togglePool,
      };
    },
    nextItem() {
      return this.itemPool[this.curItemPos + 1];
    },
    prevItem() {
      return this.itemPool[this.curItemPos - 1];
    },
    nextItemImage() {
      let imageProp, path;
      if (this.nextItem) {
        imageProp = this.nextItem.pinfo.find((e) => e.ptype == "image");
        path = this.apiUrl + "/media/zar/" + this.nextItem.obj + "/" + this.nextItem.zid + "/" + imageProp.id + "/" + "t" + imageProp.val.first().id + ".jpg";
      }
      return this.nextItem ? path : null;
    },
    prevItemImage() {
      let imageProp, path;
      if (this.prevItem) {
        imageProp = this.prevItem.pinfo.find((e) => e.ptype == "image");
        path = this.apiUrl + "/media/zar/" + this.prevItem.obj + "/" + this.prevItem.zid + "/" + imageProp.id + "/" + "t" + imageProp.val.first().id + ".jpg";
      }
      return this.prevItem ? path : null;
    },

    zarItems() {
      return this.$store.state.zar.items;
    },
    itemSource() {
      return this.$store.state.zar.itemSource;
    },
    menuIcon() {
      if (!this.itemSource) return null;

      let src = this.itemSource.src;
      switch (true) {
        case src == "homeZarList":
          return "i-home3";
        case src == "newItems":
          return "i-required";
        case src == "barter_car":
          return "i-refresh2";
        case src == "most_viewed":
          return "i-increase";
        case src == "collect":
          return "i-check-solid2";
        case src == "favs":
          return "i-bookmark";
        default:
          return "i-menu2";
      }
    },
    itemPool() {
      return this.$store.getters["zar/itemPool"];
    },
    refreshZarList() {
      return this.$store.state.user.refreshZarList;
    },
    scr() {
      return this.$store.getters.scr;
    },
    catData() {
      return this.$store.state.zar.catData;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    filter() {
      return this.$store.state.zar.filter.filter.home;
    },
    curItemPos() {
      let current = this.itemPool.find((e) => e.zid == this.zid);
      return this.itemPool.indexOf(current);
    },
    calcWidth() {
      let calc = (this.$store.state.swidth - 1300) / 2;
      return calc > 0 ? calc - 30 : 0;
    },
    // showMenu: {
    //   get() {
    //     return this.$store.state.showMenu;
    //   },
    //   set(ob) {
    //     this.$store.commit("showMenu", ob);
    //   },
    // },
    selObj: {
      get() {
        return this.$store.state.selObj;
      },
      set(ob) {
        this.$store.commit("selObj", ob);
      },
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    // zarFimage() {
    //   return this.zarImage;
    // },
    obj() {
      return this.drawerMode && this.dataSet && this.dataSet.obj ? this.dataSet.obj : parseInt(this.$route.query.o);
    },
    zid() {
      return this.drawerMode && this.dataSet && this.dataSet.zid ? this.dataSet.zid : this.$route.query.z;
    },
  },
  watch: {
    zid: {
      immediate: true,
      async handler() {
        this.reset();
        if (this.zid) {
          let timer = setTimeout(
            () =>
              this.$toast.loading({
                duration: 0,
                forbidClick: false,
              }),
            200
          );

          this.$store.commit("zar/witem", { obj: this.obj, zid: this.zid });
          // let timer = setTimeout(() => (this.showPlaceholder = true), 300);
          // setTimeout(() => (this.showPlaceholder = false), 1000);
          // this.showPlaceholder = true;
          await this.prepData();
          this.checkItemExpired();
          clearTimeout(timer);
          this.$toast.clear();
          // clearTimeout(timer);
        } //else {        }
      },
    },
    catData() {
      if (this.catData) this.checkItemExpired();
    },
    refreshZarList() {
      this.prepData();
    },
  },

  created() {
    // console.log(this.zid);
  },
  activated() {
    // this.playSound("noti.mp3", 1);
  },
  async mounted() {
    // SHOW ITEM POOL ONCE IN MONTH
    // let dayOfMonth = new Date().getDate();
    // if (this.scr == 4 && this.itemPool.length && this.routeFrom != "zarView") {
    //   if (!this.hasLocal("dayOfViewedPool")) {
    //     this.showPoolList(dayOfMonth);
    //     return;
    //   }
    //   if (this.getLocal("dayOfViewedPool") != dayOfMonth) {
    //     this.showPoolList(dayOfMonth);
    //   }
    // }

    this.$bus.on("updateViewHistory", (this.showReport = true));
    // console.log(this.drawerMode);
    // if (this.drawerMode) this.$bus.emit("scrollLock", true);

    // if (!this.obj || !this.zid)
    //   this.$modal.show("dialog", {
    //     text: "Зарын хаяг буруу байна",
    //     buttons: [
    //       {
    //         title: "Ok",
    //         handler: () => {
    //           this.$modal.hide("dialog");
    //           this.$router.push({ name: "home" });
    //         },
    //       },
    //     ],
    //   });
  },
  destroyed() {
    // this.$bus.emit("scrollLock", false);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_view {
  /* padding-top: 10px; */
  padding-bottom: 100px;
  position: relative;
  transition: all 100ms;
}

/*
.cont_zar_view.mobile_view {
  padding-bottom: 500px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgb(255, 255, 255) 5%, rgba(0, 103, 114, 0) 100%) !important;
}
*/

/* .cont_zar_view.loading {
  opacity: 0.3;
  filter: saturate(0);
} */

.expand {
  max-height: 500px !important;
}
.row {
  display: grid;
  grid-template-columns: 2.5fr 1.35fr;
  grid-gap: 20px;
}
.col1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.col2 {
  padding-top: 20px;
  position: relative;
  /* position: absolute;
  right: 0px;
  margin-right: -250px; */
}

.stick {
  position: sticky;
  top: 25px;
}

.expired {
  background-color: #ffc81577;
  padding: 10px 20px;
  text-align: center;
  font-weight: 380;
  line-height: 1.5em;
  font-size: 16px;
  width: fit-content;
  font-stretch: 95%;
  margin-bottom: 20px;
}

.bottom_item_pool {
  /* background-color: #fff !important; */
  /* border: solid 1px #ccc; */
  /* border-radius: 8px; */
  /* background-color: #ffeded; */
  /* padding: 10px; */
  /* text-align: center; */
}
/*
.col2 .col_sep {
  position: absolute;
  width: 2px;
  height: 20%;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  border-right: dotted 3px #e4e4e4;
} */

/* .col2::before {
  position: absolute;
  left: -2vh;
  content: "";
  width: 1px;
  height: 40%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e4e4e4;
} */
.cont_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cat_playing {
  max-width: 350px;
}
.cont_pool {
  position: fixed;
  right: 85px;
  height: 80%;
  width: 300px;
  top: 50%;
  transform: translateY(-50%);
}
.wrap_pool {
  border: solid 1px #ccc;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  height: 80%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  border: solid 1px #ccc;
}
.cont_back.right {
  position: fixed;
  right: 20px !important;
  top: 50%;
  transform: translateY(-100px);
}
.cont_back.bottom {
  position: fixed;
  top: 50%;
  right: 30px;
  background-color: rgba(88, 255, 255, 0.651);
  border-radius: 5px;
  padding: 5px 10px;
  /* transform: translateY(-50%); */
}
.cont_back.left {
  position: fixed;
  left: 0;
  top: 0;
  width: 20vh;
  height: 100%;
  background-color: #f1f1f1;
  opacity: 0;
  transition: all 300ms;
}
.cont_back.left:hover,
.cont_back.left:hover + .left_back {
  opacity: 0.5;
  cursor: pointer;
}
.cont_back.left div,
.left_back {
  position: fixed;
  left: 25px;
  top: 25px;
  font-weight: var(--base_weight);
  font-stretch: 95%;
  opacity: 0.3;
  transition: all 300ms;
  pointer-events: none;
}
.cont_back.left div {
  left: 50px;
  top: 27px;
}
.left_back:hover {
  cursor: pointer;
}
.back_icon {
  opacity: 0.6;
}
.back_icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.cont_navi {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  user-select: none;
  transition: all 150ms;
}
.cont_navi:hover {
  filter: brightness(0.95);
}
.cont_navi:active .navi_image {
  transform: scale(0.97);
}
.cont_navi .count {
  color: #525252;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}
/* .cont_navi .count::before,
.cont_navi .count::after {
  font-size: 8px;
  position: relative;
  top: -2px;
  margin: 0 1px;
} */
/* .cont_navi .count::before {
  content: "(";
}
.cont_navi .count::after {
  content: ")";
} */
.trigger {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(60px);
  font-size: 12px;
  opacity: 1;
  transition: all 200ms;
  text-align: right;
}
.trigger:hover {
  opacity: 1;
  background-color: #f1f1f1;
  cursor: pointer;
}
.navi_image {
  overflow: hidden;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 200ms;
  border-radius: 2px;
  user-select: none;
}

.cont_social {
  display: flex;
  gap: 10px;
  margin-top:20px;
}
.related_items {
  background-color: #f1f1f1;
  padding: 7px 10px;
}

.most_viewed {
  background-color: rgba(137, 43, 226, 0.075);
  padding: 7px 10px;
}

.related_items,
.most_viewed,
.bottom_item_pool {
  /* padding: 10px; */
  margin-top: 30px;
  text-align: center;

  /* background-color: #f1f1f1; */
  /* border-radius: 8px; */
}
.related_items .title,
.most_viewed .title,
.bottom_item_pool .title {
  /* text-align: center; */
  /* width: 100%; */
  /* top: -10px;
  left: 5px; */
  /* color: #fff !important; */
}

.button_back.left {
  position: fixed;
  left: 0px;
}

.cont_navi.next .navi_image {
  padding: 40px 20px;
  opacity: 1;
}

.cont_navi.prev .navi_image {
  padding: 25px 20px;
}

.navi_image .icon {
  opacity: 0.2;
}

.cont_navi.prev {
  right: 70px;
}
.cont_navi.next {
  right: 0px;
}

.navi_image img {
  max-width: 80px;
  max-height: 80px;
}

.navi_image:hover {
  cursor: pointer;
  opacity: 1;
}

.back_button {
  transform: translateX(-50%);
  position: absolute;
}

.navi_simple {
  /* position: relative; */
  /* padding: 10px 0; */
}
.navi_simple .left,
.navi_simple .right {
  padding: 20px;
  position: fixed;
  bottom: 10%;
  /* background-color: aqua; */
}

.navi_simple .left img,
.navi_simple .right img {
  padding: 10px;
  background-color: #97c2d67a;
  /* border: solid 1rgba(219, 226, 239, 0.637); */
  /* padding: 4px 7px 2px 7px; */
  /* border-radius: 5px; */
}

/* .navi_simple .left img {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.navi_simple .right img {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
} */

.navi_simple .left {
  left: -20px;
}
.navi_simple .right {
  right: -20px;
}

.cont_image {
  line-height: 0;
  column-count: 2;
  column-gap: 3px;
  column-fill: balance;
}
.cont_image div {
  width: 100% !important;
  height: 300px !important;
  margin-bottom: 3px;
}

.placeholder {
  background-color: #444;
  min-height: 12px;
  border-radius: 3px;
  opacity: 0.1;
  min-width: 10px;
  animation: fading 1.5s infinite;
}
.post {
  width: 100%;
  margin: 10px;
  padding: 10px 20px;
  /* box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15); */
}
.post-image {
  width: 100%;
  height: 350px;
  border-radius: 0;
}

.text {
  width: 100%;

  height: 24px;
}

.content_back {
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
}

.bottom_marker {
  position: absolute;
  bottom: 0px;
  /* color: transparent; */
  color: red;
}

.copy_input {
  opacity: 0;
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

@media only screen and (max-width: 700px) {
  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  .col1 {
    text-align: left;
  }
  .col2 {
    padding-top: 0px;
  }

  .expired {
    width: inherit;
    margin-top: 20px;
  }
}

/* @media only screen and (max-width: 1400px) {
  .cont_navi {
    display: none;
  }
} */
@media only screen and (min-width: 1500px) {
  .content_back {
    display: none;
  }
}
</style>
